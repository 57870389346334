<template>
  <div class="member-shop-point-exchange-prodct-edit-info-block">
    <p class="card-title">兌換規則設定</p>

    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <el-form-item v-if="contextProductType === 'coupon'" label="兌換點數類型" required>
        <el-radio-group v-model="formData.pointType" prop="pointType">
          <el-radio label="system">系統點數</el-radio>
          <el-radio v-if="useExternalPoint" label="external">第三方點數</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="點數兌換規則" required>
        <el-form-item>
          <el-radio-group v-model="formData.mode" prop="mode" class="flex flex-col" style="gap: 20px; padding-top: 8px;" @change="onRulesModeChange">
            <div v-if="!syncIsPaymentPromise" class="flex flex-col">
              <el-radio label="all">點數全額兌換</el-radio>
              <div style="margin-left: 25px; margin-top: 8px">
                <el-form-item prop="full.point">
                  <div class="flex" style="gap: 20px">
                    <el-input v-model="formData.full.point" :disabled="formData.mode !== 'all'" style="width: 100px" placeholder="請輸入" />
                    <p>點可兌換一個</p>
                  </div>
                </el-form-item>
              </div>
            </div>

            <div v-if="contextProductType === 'classTicket'">
              <el-radio label="part">點數加價兌換</el-radio>
              <div class="flex" style="margin-left: 25px; margin-top: 8px; gap: 20px">
                <el-form-item prop="part.point">
                  <div class="flex items-center" style="gap: 20px">
                    <el-input v-model="formData.part.point" :disabled="formData.mode !== 'part'" style="width: 100px" placeholder="請輸入" />
                    <span>點，加上＄</span>
                  </div>
                </el-form-item>
                <el-form-item prop="part.price">
                  <div class="flex items-center" style="gap: 20px">
                    <el-input v-model="formData.part.price" :disabled="formData.mode !== 'part'" style="width: 100px" placeholder="請輸入" />
                    <span>元可兌換一個</span>
                  </div>
                </el-form-item>
              </div>
            </div>
          </el-radio-group>
        </el-form-item>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref, onMounted, nextTick, watch } from 'vue'
import { isDigitRules, noEmptyRules, minRules } from '@/validation'
import { get } from 'lodash'
import formUtils from '@/utils/form'
import formsContext from '../context'
import { usePermissions } from '@/use/permissions'
export default defineComponent({
  name: 'MemberShopPointExchangeProdctEditRulesBlock',
  components: { },
  props: {
    productData: { type: Object, default: () => ({}) },
    context: { type: Object, default: () => ({}) },
    isPaymentPromise: { type: Boolean, default: false },
  },
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const useExternalPoint = computed(() => checkAction('admin.externalPointWallet.getExternalPoint'))
    const contextProductType = computed(() => {
      const productDataType = get(props.productData, 'type')
      return productDataType || get(props.context, 'type')
    })

    const syncIsPaymentPromise = computed(() => {
      return get(props, 'isPaymentPromise') && contextProductType.value === 'classTicket'
    })
    watch(syncIsPaymentPromise, (val) => {
      if (val) formData.mode = 'part'
      else formData.mode = 'all'
    })
    watch(contextProductType, (val) => {
      if (val === 'classTicket') formData.mode = 'part'
      else formData.mode = 'all'
    })
    const formRef = ref(null)
    const formData = reactive({
      mode: 'all',
      full: { point: null },
      part: {
        point: null,
        price: null,
      },
      pointType: 'system',
    })
    const formRules = computed(() => {
      const rules = {
        mode: [noEmptyRules()],
      }

      if (formData.mode === 'all') {
        rules['full.point'] = [noEmptyRules(), isDigitRules(), minRules(1)]
      }
      if (formData.mode === 'part') {
        rules['part.point'] = [noEmptyRules(), isDigitRules(), minRules(1)]
        rules['part.price'] = [noEmptyRules(), isDigitRules(), minRules(1)]
      }

      return rules
    })
    const compactData = computed(() => {
      const mode = formData.mode
      let data = {}
      if (mode === 'all') {
        let amountKey
        const amount = Number(get(formData, 'full.point'))
        if (formData.pointType === 'external') amountKey = 'externalPointAmount'
        else amountKey = 'pointExchangeAmount'
        data = {
          [amountKey]: amount,
          price: 0,
        }
        if (amountKey === 'pointExchangeAmount') {
          data.externalPointAmount = 0
        }
      }
      if (mode === 'part') {
        data = {
          pointExchangeAmount: Number(get(formData, 'part.point')),
          price: Number(get(formData, 'part.price')),
        }
      }
      return data
    })

    const onRulesModeChange = (mode) => {
      formData.full.point = null
      formData.part.point = null
      formData.part.price = null
      formUtils.clearValidate(formRef.value)
    }

    const syncData = () => {
      const data = props.productData
      const price = get(data, 'price')
      const externalPointAmount = get(data, 'externalPointAmount')
      const point = externalPointAmount || get(data, 'pointExchangeAmount')
      if (externalPointAmount) formData.pointType = 'external'
      if (!price) {
        formData.mode = 'all'
        formData.full.point = point
      }
      if (price) {
        formData.mode = 'part'
        formData.part.point = point
        formData.part.price = price
      }
    }

    watch(formData, () => {
      formsContext.setFormData('rules', { ...compactData.value })
    })

    onMounted(async () => {
      await nextTick()
      formsContext.setFormRef('rules', formRef.value)

      if (get(props.productData, 'id')) syncData()
    })

    return { formRef, formData, formRules, onRulesModeChange, contextProductType, get, syncIsPaymentPromise, compactData, useExternalPoint }
  },
})

</script>

<style scoped lang="postcss">

</style>
