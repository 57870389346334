import { render, staticRenderFns } from "./MemberShopPointExchangeProdctEdit.vue?vue&type=template&id=723b014b&scoped=true"
import script from "./MemberShopPointExchangeProdctEdit.vue?vue&type=script&lang=js"
export * from "./MemberShopPointExchangeProdctEdit.vue?vue&type=script&lang=js"
import style0 from "./MemberShopPointExchangeProdctEdit.vue?vue&type=style&index=0&id=723b014b&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "723b014b",
  null
  
)

export default component.exports